<template>
<s-header :name='"-考生照片重拍"'></s-header>
<!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
<div class="van-cell">
    <div class="van-cell__title">
        <span>考生姓名</span>
    </div>
    <div class="van-cell__value">
        {{$route.query.name}}
    </div>
</div>
<div style="width:100%;height:100%;background:#F6F6F6;">
        <van-notice-bar
        wrapable
        :scrollable="false"
        text="请使用手机摄像头拍摄本人正面头像证件照，背景要求白墙或白布，照片打印在准考证上"
        />
    <br/>
    <div style="margin-left:5%;">
        <van-uploader  v-model="fileList"  :after-read="afterRead" :max-count="1" :previewSize="120" />
    </div>
    <br/>
    
    <div style="margin: 10px;">
        <div>
        <van-button @click="backUrl" style="width:48%;" plain type="primary" size="normal">
            <span style="color:#555555;">返回</span>
        </van-button>
        <van-button @click="editAvatar"  style="width:48%;margin-left:4%"  type="primary" size="normal" native-type="submit">
        <span >提交修改</span>
        </van-button>
        </div>
    </div>
    <div class="bottom-bg"></div>
</div>
</template>
<script>
import { onMounted, reactive,ref } from 'vue';
import sHeader from '@/components/Header'
import {updatePhoto} from '@/apis/student'
import { useRouter,useRoute } from 'vue-router';
import { Toast } from 'vant'
import { upload} from '@/apis/user';
import { prefix} from '@/utils/function'

export default {
   components: {
    sHeader
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const fileList = ref([]);
    const state = reactive({
        avatar:''
    });

    if(route.query.avatar){
        fileList.value.push({ url: prefix(route.query.avatar) });
    }
   
    onMounted(()=>{
        // route.query.avatar;
        // fileList.value.map()
        // console.log(fileList.value);
    })
    const afterRead = async(file) => { // 此时可以自行将文件上传至服务器
        Toast.loading({
            message: '上传中...',
            forbidClick: true,
        });

        // let canvas = document.createElement("canvas");
        // let context = canvas.getContext("2d");// 获取对应的CanvasRenderingContext2D对象(画笔)
        // let img = new Image(); // 创建新的图片对象
        // let formdata = new FormData();
        // img.src = file.content;// 指定图片的DataURL(图片的base64编码数据)
        // img.onload = async() => {// 监听浏览器加载图片完成，然后进行进行绘制
        //     let	w = img.width/0.7;
        //     let	h = img.height/0.7;
        //     canvas.width = w;
        //     canvas.height = h; // 计算等比缩小后图片
        //     context.drawImage(img, 0, 0, w, h)
        //     file.content = canvas.toDataURL(file.file.type, 0.7); // 将绘制完成的图片重新转化为base64编码，file.file.type为图片类型，0.92为默认压缩质量
        //     formdata.append("file",base64UrlToFile(file.content,file.name));
        //     let updateData = await upload(formdata);
        //     Toast('上传成功');
        //     state.avatar = updateData;
        // };
      let formdata = new FormData();
      formdata.append("file",file.file);
      let updateData = await upload(formdata);
      Toast('上传成功');
      state.avatar = updateData;
    };

    //  const base64UrlToFile = (dataurl, filename = 'file') => {
    //     let arr = dataurl.split(',')
    //     let mime = arr[0].match(/:(.*?);/)[1]
    //     let suffix = mime.split('/')[1]
    //     let bstr = atob(arr[1])
    //     let n = bstr.length
    //     let u8arr = new Uint8Array(n)
    //     while (n--) {
    //       u8arr[n] = bstr.charCodeAt(n)
    //     }
    //     return new File([u8arr], `${filename}.${suffix}`, {type: mime})
    // }

    const editAvatar=async()=>{
        let params = { 
            id:route.query.id,
            name:route.query.name,
            avatar:state.avatar
        };
        console.log(params);
        await updatePhoto(params);
        Toast('修改成功');
        router.go(-1);
    }

    const getImgUrl= (img)=>{
      return "http://www.baoming.com/"+img;
    }

    const backUrl = () => {
      router.go(-1);
    }

    return {
      state,
      fileList,
      afterRead,
      backUrl,
      getImgUrl,
      editAvatar
    };
  },
};
</script>
<style  scoped>
.font-color{
    color:#555555;
}
.van-row{
    border-bottom:1px solid #EBEBEB;
}
.van-col{
    height:4em;line-height:4em;
}
.bottom-bg {
  height: 0.9rem;
  width: 100%;
  /* background-color: #F6F6F6; */
  border: none;
}
.demo-preview{
    background: #ffff;
    margin:10px;
    height:9em;
}
p{
    color:#747474
}
.clbum-info{
    float:left;margin-left:5%;margin-top:7px;font-size:13px;width:80%;
}
h1{
    float:left;margin-top:25px;
}
.clbum-share{
float:right;margin-right:3%;
}

</style>