<template>
<s-header></s-header>
<div style="margin:10px;">
    <router-link tag="i" to="/login/student"><van-button plain type="primary" size="large"><span class="font-color">考生登录</span></van-button></router-link><br/><br/>
    <router-link tag="i" to="/login/school"><van-button plain type="primary" size="large"><span class="font-color">学校主考登录</span></van-button></router-link><br/><br/>
    <router-link tag="i" to="/login/clbum"><van-button plain type="primary" size="large"><span class="font-color">班主任登录</span></van-button></router-link><br/><br/>
    <router-link tag="i" to="/login/4"><van-button plain type="primary" size="large"><span class="font-color">考室画室检索/查验</span></van-button></router-link><br/><br/>
    <!-- <router-link tag="i" to="/marking-login"><van-button plain type="primary" size="large"><span class="font-color">优秀试卷查询</span></van-button></router-link><br/><br/> -->
    <router-link v-if="state.grade_config=='1'" tag="i" to="/grade-record"><van-button plain type="primary" size="large"><span class="font-color">成绩录入</span></van-button></router-link>
</div><br/>


<!-- <van-button @click="testStore" plain type="primary" size="large"><span class="font-color">点击测试</span></van-button> -->

<div class="bottom">
    <span style="color:red;">备注:</span>
    <ul>
        <li>
            1.考生凭所在学校/画室的考点代码和班级注册码登录.
        </li>
        <li>
            2.学校主考凭组委会发放的考点代码和激活码登录
        </li>
        <li>
            3.班主任凭考点代码和  主考设置的班主任密码登录
        </li>
        第一次完成信息验证后下次使用本人微信无需登录即可进入系统.<br/>
        考点/报名点未收到考点激活码的请联系我们 4000829858
    </ul>
</div>

</template>
<script>
import { onMounted, reactive } from 'vue';
import sHeader from '@/components/Header';
import { useStore } from 'vuex'
import { useRouter,useRoute } from 'vue-router';
// import { decrypt } from '@utils/openssl';
import { decrypt} from '@/utils/openssl'
import { configList } from "@/apis/config.js";
import { getConfig} from "@/utils/function";
export default {
  components: {
    sHeader
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      username: '',
      password: '',
      grade_config:'',
    });
   onMounted(async()=>{
     let config = await configList();
     localStorage.setItem('config', JSON.stringify(config));
     state.grade_config = getConfig('grade_record').value;//获取成绩录入显示配置
    //  https://www.yszytb.com/api/v1/system/wechatOauth
    // let queryArr = Object.keys(route.query); 
    // console.log(queryArr);
    console.log(route.query.q);
    if(Object.keys(route.query).indexOf('out')==-1){//indexOf可以判断数组是否包含某个值，返回该值所对应的下标，对于不存在的值，返回 -1
        if(typeof route.query.q=='undefined'){
          // //  window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2b60ba13efb06448&redirect_uri=https://www.yszytb.com/api/v1/system/wechatUser&response_type=code&scope=snsapi_base&state=123#wechat_redirect"
            window.location.href = 'https://www.yszytb.com/api/v1/system/wechatOauth?url='+window.location.href;
        }else{
            let open_id = decrypt(route.query.q);
            localStorage.setItem('open_id', open_id);
        }
    }
    let token = localStorage.getItem('token');
    if (token !== null && token !== undefined && token !== '') {
        router.push({path:"dashboard"});
    }
   })
   
    const store = useStore();
    const onSubmit = (values) => {
      console.log('submit', values);
    };
    const testStore=()=>{
      console.log(store.state.user);
      store.commit("setUserInfo",{name:"45625",remark:"测试store功能"});
    }
    return {
      state,
      onSubmit,
      testStore
    };
  },
};
</script>
<style  scoped>
.font-color{
    color:#555555;
}
.bottom{
  font-size: 1.1em;
  margin-left:10px;
}

</style>