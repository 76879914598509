
import axios from '../utils/axios'

export function clbumList(query) {//考点详情
    console.log({params:query});
    return axios.get(`/clbum`,{params:query})
}
export function addClbum(params)
{
    return axios.post(`/clbum`,params)
}
export function delClbum(params)
{
    return axios.delete(`/clbum`,{data:params})
}

export function updateClbum(params)
{
    return axios.put(`/clbum`,params)
}

export function clbumInfo(query)
{
    return axios.get(`/clbum/show`,{params:query})
}

export function studentList(query)
{
    return axios.get(`/clbum/student_list`,{params:query})
}

// export function delClbum(id) {
//     return axios.delete(`/clbum/${id}`);
// }