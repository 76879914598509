<template>
  <s-header :name="'-班级管理'"></s-header>
  <!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
  <div class="van-cell">
    <div class="van-cell__title">
      <span>已选报名点 </span>
    </div>
    <div v-if="active==0" class="van-cell__value">
      {{ checked.join(",") }}
    </div>

    <div v-if="active==1" class="van-cell__value">
      {{ checked1.join(",") }}
    </div>
  </div>
  <div class="van-cell">
    <div class="van-cell__title">
      <span>所在班级</span>
    </div>
    <div class="van-cell__value">
      {{ state.info.name }}
    </div>
  </div>

  <van-cell-group inset>
    <van-field
      v-model="state.info.name"
      name="name"
      label="班级名称"
      placeholder="班级名称"
      :rules="[{ required: true, message: '请填写班级名称' }]"
    />
    <van-field
      v-model="state.info.password"
      name="password"
      label="班主任密码"
      placeholder="班主任密码"
      :rules="[{ required: true, message: '班主任密码' }]"
    />
  </van-cell-group>

  <div style="background: #f6f6f6; width: 100%; height: auto">
    <van-notice-bar
      color="#1989fa"
      wrapable
      background="#ecf9ff"
      left-icon="info-o"
    >
      你可在此设置该班参加的检测项目,该班考生报名时自动依此设置选择检测.如需报名本校检测,请切换到本校检测进行选择;请选择<span
        style="color: #ee0a24"
        >"{{ state.info.name }}"</span
      >的检测项目
    </van-notice-bar>
    <van-tabs  v-model:active="active">
      <van-tab title="公共检测">
          <div class="van-cell">
            <div class="van-cell__title">
              <span>快捷选择</span>
            </div>
            <div class="van-cell__value">
              <van-button
                @click="toggleAll"
                plain
                type="primary"
                size="small"
                native-type="submit"
              >
                <span>反选</span>
              </van-button>&nbsp;&nbsp;

              <van-button
                @click="checkAll"
                plain
                type="primary"
                size="small"
                native-type="submit"
              >
                <span>全选</span>
              </van-button>
            </div>
          </div>

          <van-checkbox-group v-model="checked" ref="checkboxGroup">
            <div
              v-for="(item, index) in state.list"
              :key="index"
              class="demo-preview"
            >
              <div style="margin-left: 5%">
                <h1>{{index+1}}</h1>
                <!-- <h1>{{ item.id }}</h1> -->
                <div class="clbum-info">
                  <h3 v-if="item.status == '0'">
                    {{ item.name }}(<span style="color: #07c160">进行中</span>)
                  </h3>
                  <h3 v-if="item.status == '1'">
                    {{ item.name }}(<span style="color: #ee0a24">已截止</span>))
                  </h3>
                  <p>检测时间：{{ item.testd_at }} 检测费：{{ item.price }}</p>
                </div>
              </div>
              <van-checkbox
                style="position: relative; top: 30%"
                v-if="item.status == '0'"
                :name="item.id"
              ></van-checkbox>
            </div>
          </van-checkbox-group>
          <br />
      </van-tab>

      <van-tab title="本校检测">
        <div class="van-cell">
          <div class="van-cell__title">
            <span>快捷选择</span>
          </div>
          <div class="van-cell__value">
            <van-button
              @click="toggleAll1"
              plain
              type="primary"
              size="small"
              native-type="submit"
            >
              <span>反选</span>
            </van-button>&nbsp;&nbsp;

            <van-button
              @click="checkAll1"
              plain
              type="primary"
              size="small"
              native-type="submit"
            >
              <span>全选</span>
            </van-button>
          </div>
        </div>

        <van-checkbox-group v-model="checked1" ref="checkboxGroup1">
            <div
              v-for="(item, index) in state.school_test_list"
              :key="index"
              class="demo-preview"
            >
              <div style="margin-left: 5%">
                <h1>{{index+1}}</h1>
                <!-- <h1>{{ item.id }}</h1> -->
                <div class="clbum-info">
                  <h3 v-if="item.status == '0'">
                    {{ item.name }}(<span style="color: #07c160">进行中</span>)
                  </h3>
                  <h3 v-if="item.status == '1'">
                    {{ item.name }}(<span style="color: #ee0a24">已截止</span>))
                  </h3>
                  <p>检测时间：{{ item.testd_at }} 检测费：{{ item.price }}</p>
                </div>
              </div>
              <van-checkbox
                style="position: relative; top: 30%"
                v-if="item.status == '0'"
                :name="item.id"
              ></van-checkbox>
            </div>
          </van-checkbox-group>
          <br />
      </van-tab>
  </van-tabs>


    <div style="margin: 10px">
      <van-button block type="primary" @click="onStep">
        <span>确认选择</span>
      </van-button>
    </div>

    <div class="bottom-bg"></div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import sHeader from "@/components/Header";
import { examList } from "@/apis/exam.js";
import { updateClbum, clbumInfo } from "@/apis/clbum.js";
import {list} from "@/apis/school_test";
// import { Toast,Dialog } from 'vant'
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import { Toast } from 'vant';
export default {
  components: {
    sHeader,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const checked = ref([]);
    const checkboxGroup = ref(null);

    const checked1 = ref([]);
    const checkboxGroup1 = ref(null);
    const active = ref(0);

    const check=ref([]);
    const state = reactive({
      addShow: false,
      addId: 0,
      list: {},
      school_test_list:{},
      info: {},
    });
    onMounted(async () => {
      let params = { id: route.query.id };
      state.info = await clbumInfo(params);
      if (state.info.exam_id) {
        checked.value = state.info.exam_id.split(",");
      }
      if (state.info.self_exam_id) {
        checked1.value = state.info.self_exam_id.split(",");
      }
      state.list = await examList();
      state.school_test_list = await list({school_id:state.info.school_id});
      console.log(state.school_test_list);
      console.log(state.list);
    });

    const checkAll = () => {
      checkboxGroup.value.toggleAll(true);
    };
    const toggleAll = () => {
      checkboxGroup.value.toggleAll();
    };

    const checkAll1 = () => {
      checkboxGroup1.value.toggleAll(true);
    };
    const toggleAll1 = () => {
      checkboxGroup1.value.toggleAll();
    };

    const onStep = async () => {
      console.log('active',active.value);
      if(active.value){
        check.value = checked1.value;//本校检测
      }else{
        check.value = checked.value;//公共检测项目
      }
      if(check.value.length==0){
          Toast('请选择检测项目');
          return;
      }
      let params = {
        id: state.info.id,
        active:active.value,
        exam_id:active.value?'':check.value.join(","),
        self_exam_id: active.value?check.value.join(","):'',
        name: state.info.name,
        password: state.info.password,
      };
      await updateClbum(params);

      router.push({ path: "clbum-confirm", query: { id: state.info.id } });
    };
    return {
      state,
      checkAll,
      toggleAll,
      checkAll1,
      toggleAll1,
      onStep,
      active,
      checked,
      checkboxGroup,
      checked1,
      checkboxGroup1,
    };
  },
};
</script>
<style  scoped>
.font-color {
  color: #555555;
}
.van-row {
  border-bottom: 1px solid #ebebeb;
}
.van-col {
  height: 4em;
  line-height: 4em;
}
.bottom-bg {
  height: 0.9rem;
  width: 100%;
  background-color: #f6f6f6;
  border: none;
}
.demo-preview {
  background: #ffff;
  margin: 10px;
  height: 9em;
}
p {
  color: #747474;
}
.clbum-info {
  float: left;
  margin-left: 5%;
  margin-top: 7px;
  font-size: 13px;
  width: 80%;
}
h1 {
  float: left;
  margin-top: 25px;
}
.clbum-share {
  float: right;
  margin-right: 3%;
}
</style>