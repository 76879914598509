<template>
<s-header :name='"-考生信息"' ></s-header>
<div style="background:#F6F6F6;width:100%;height:auto;">
    <van-notice-bar  wrapable color="#1989fa" background="#ecf9ff" left-icon="info-o">
      说明:已缴费考生不可删除,点击修改考生信息
    </van-notice-bar>

    
    <div @click="daiEnrollUrl(item.id)" v-for="(item, index) in state.list" :key="index" class="demo-preview">
        <div style="margin-left:5%;">
            <h1>{{index+1}}</h1>
            <div class="clbum-info">
            <h3>{{item.name}}-<span v-if="item.sex=='0'">男</span><span v-if="item.sex=='1'">女</span>-{{item.num}}</h3>
            <p>
              检测:{{item.exam_id}}
              <span style="color:#EE0A24" v-if="item.status=='0'">未缴费:{{item.price}}</span>
              <span style="color:#07C160" v-if="item.status=='1'">已缴费:{{item.price}}</span>
            </p>
            </div>
        </div>
    </div>
    <br/>

      <div style="margin: 0px 10px;margin-bottom:30px;">
        <van-button @click="back" block plain type="primary" size="normal">
            <span style="color:#555555;">返回主页</span>
        </van-button>
        
      </div>
    <div class="bottom-bg"></div>
</div>

</template>
<script>
import { onMounted, reactive } from 'vue';
import { useRouter,useRoute } from 'vue-router';
import sHeader from '@/components/Header';
import { studentList } from "@/apis/clbum.js";
import { Toast } from 'vant';

export default {
   components: {
    sHeader
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      value: '',
      showPicker: false,
      list:{},
      exam_list:{},
      columns:[],
      info:{
        the_sketch:'',
        sketch:'',
        color:'',

      }
    });
    onMounted(async()=>{
       state.list = await studentList({clbum_id:route.query.clbum_id});
    })
    const onSubmit = async (values) => {
      console.log(values);
      Toast('操作成功');
      router.go(0);
    };

    const back=()=>{
        // router.go(-1);
        router.push({ path: '/dashboard'});
        // { path: '/dashboard'}
    }

    const daiEnrollUrl = (student_id) => {
        console.log(student_id);
      router.push({ path: '/dai-enroll',query:{student_id:student_id,type:'info'}});
    }
    return {
      state,
      onSubmit,
      back,
      daiEnrollUrl
    };
  },
};
</script>
<style  scoped>
.demo-preview{
    background: #ffff;
    margin:10px;
    height:8em;
}
p{
    color:#747474
}
.clbum-info{
    float:left;margin-left:5%;margin-top:7px;font-size:13px;width:80%;
}
h1{
    float:left;margin-top:25px;
}
.clbum-share {
  float: right;
  margin-right: 3%;
}
.bottom-bg {
  height: 0.9rem;
  width: 100%;
  background-color: #f6f6f6;
  border: none;
}
</style>