<template>
<s-header :name='"-报名连接"' ></s-header>
    <van-notice-bar wrapable color="#1989fa" background="#ecf9ff" left-icon="info-o">
       请点击右上角,将连接发送给考生;进入页面自主报名.
    </van-notice-bar>
    <van-cell-group>
        <van-cell title="考点名称：" :value="state.clbum.school_name" />
        <van-cell title="所在班级：" :value="state.clbum.name" />
    </van-cell-group>
    <!-- <div style="margin-left:15px;font-size:13px;"><span style="color:red">说明：</span>请仔细核对以下信息是否正确，如不正确可直接修改</div><br/> -->
   <div v-if="state.but==true" style="margin: 10px;">
    <van-button @click="daiEnrollUrl(state.clbum.id)" plain block type="primary">
      <span style="color:#555555;">确认进入报名流程</span>
    </van-button>
  </div>
</template>
<script>
import { onMounted, reactive } from 'vue';
import sHeader from '@/components/Header';
import {clbumInfo} from '@/apis/clbum';
import { ref } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import wechatInterface from "@/utils/wechatInterface";
import {getJssdk} from '@/apis/student';
import { Dialog } from 'vant'
import { decrypt} from '@/utils/openssl'
export default {
   components: {
    sHeader
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const fileList = ref([]);
    const state = reactive({
      jssdk:{},
      showArea: false,
      clbum:{},
      but:false
    });

    onMounted(async()=>{
        if(route.query.bl=='bm' && typeof route.query.q=='undefined'){//亲友支付,并且不是回调回来的页面.调用授权.....
            window.location.href = `https://www.yszytb.com/api/v1/system/wechatOauth?url=${encodeURIComponent(window.location.href)}`;//传递当前授权页面,进行回调处理....
        }else{
            if(Object.keys(route.query).indexOf('q')!=-1){//如果q参数存在,解析成open_id  存储(亲友支付时会走这段逻辑)
                let open_id = decrypt(route.query.q);
                state.open_id = open_id
                localStorage.setItem('open_id', open_id);
            }
        }
        if(route.fullPath.includes("bl=bm")){//微信bug处理,跳转后会将&转义为&amp
            state.but=true;
        }
        state.jssdk = await getJssdk({url:window.location.href});
        state.clbum   =  await clbumInfo({id:route.query.clbum_id});
        onShare();//微信分享初始化.
    })

    const daiEnrollUrl = (clbum_id) => {
      router.push({ path: "/dai-enroll",query:{clbum_id:clbum_id,type:'create'} });
    };
    // const urlParam = (name)=>
    // {
    //     let url = route.fullPath;
    //     url.replace(/&amp;/g, '&');
    //     let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    //     let r = url.substr(1).match(reg);
    //     return r ? r[2] : null;
    // }
    const onShare=async()=>{
        // let link = `${process.env.VUE_APP_WEB_HTTP}/share-clbum?clbum_id=${route.query.clbum_id}&bl=bm`;
        wechatInterface(
            state.jssdk,
            () => {// 分享成功
                console.log('分享成功');
            },
            (e) => {// 分享失败
                Dialog.alert({
                    title: '提示',
                    message: '分享接口调用失败,请刷新页面重试;刷新后无此提示表示调用成功',
                    theme: 'round-button',
                }).then(() => {
                // on close
                });
                console.log(e);
            },
            {
                type:'share',
                title:state.clbum.school_name+'-'+state.clbum.name,
                desc:state.clbum.school_name+'-'+state.clbum.name+'报名连接',
                link:`${process.env.VUE_APP_WEB_HTTP}/share-clbum?clbum_id=${route.query.clbum_id}&bl=bm`,
                imgUrl:`${process.env.VUE_APP_WEB_HTTP}/630.png`
            },
        );
    }
    const onBmMsg=()=>
    {
        Dialog.alert({
            title: '提示',
            message: '请点击右上角,将连接发送给考生;进入页面自主报名.',
            theme: 'round-button',
        }).then(() => {
            // onShare();
        });
    }
    return {
      state,
      daiEnrollUrl,
      onBmMsg,
      fileList
    };
  },
};
</script>
<style  scoped>

</style>