<template>
<s-header :name='"准考证"'></s-header>
<van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
    说明:长按图片保存到手机,进行打印!
</van-notice-bar>
    <div class="b">
	<!--循环1-->
      <div class="box" ref="imageTofile" v-show="state.isShow">
        <div class="top"><img src="../../static-files/top.jpg"></div>
            <div class="con">
              <table border="1" class="use">
                <tbody><tr>
                </tr>
                <tr>
                  <td>姓名</td>
                  <td>{{state.student.name}}</td>
                  <td rowspan="4" style="width:120px;height:164px;"><img crossorigin="anonymous" :src="`${prefix(state.student.avatar)}`" border="0"></td>
                </tr>
                <tr>
                  <td>准考证号</td>
                  <td>{{state.student.num}}</td>
                </tr>
                <tr>
                  <td>所在考点</td>
                  <td>本校</td>
                </tr>
                <tr>
                  <td>所在考区</td>
                  <td>{{state.student.area_num}}考区</td>
                </tr>
                <tr>
                  <td>考场号</td>
                  <td colspan="2">考场:{{state.student.exam_num}} </td>
                </tr>
                <tr>
                  <td>座号</td>
                  <td colspan="2">{{state.student.seat_num}}</td>
                </tr>
                <tr>
                  <td>所属机构</td>
                  <td colspan="2">{{state.student.school_name}} </td>
                </tr>
              </tbody>
            </table>
            <div class="inf">
              <div class="u">
                <table border="1" class="cou">
                  <tbody><tr>
                    <td>科目</td>
                    <td>色彩</td>
                    <td>素描</td>
                    <td>速写</td>
                  </tr>
                  <tr>
                    <td>考试时间</td>
                    <td>{{state.publicExam.color_start_at}}-{{state.publicExam.color_end_at}}</td>
                    <td>{{state.publicExam.the_sketch_start_at}}-{{state.publicExam.the_sketch_end_at}}</td>
                    <td>{{state.publicExam.sketch_start_at}}-{{state.publicExam.sketch_end_at}}</td>
                  </tr>
                </tbody></table>
              </div>
              <div>
                注意事项：
                <!-- <p style="font-size:15px;text-align:left;">每一科目开考前，请务必认真检查试卷上的所贴条形码的下方号码与准考证号一致，并且在试卷上用黑色签字笔准确填写姓名信息。</p> -->
                <p style="font-size:15px;text-align:left;">每一科目开考前，请务必认真检查试卷上的所贴条形码</p>
                <p style="font-size:15px;text-align:left;">的下方号码与准考证号一致，并且在试卷上用黑色签字</p>
                <p style="font-size:15px;text-align:left;">笔准确填写姓名信息。</p>

              </div>
            </div>
            </div>
            <div class="bot"><img src="../../static-files/bt.jpg"></div>
          </div>
          <img class="show-img" :src="state.htmlUrl" alt="" v-show="!state.isShow">  <!---生成的图片,用于考生下载-->
    </div>
    <br/>

    <div style="margin: 0px 10px;">
        <div>
          <van-button @click="dashboardUrl" block plain type="primary" size="normal">
            <span style="color:#555555;">返回主页</span>
          </van-button>
          <!-- <van-button  @click="onClickRight" style="width:48%;margin-left:4%"  type="primary" size="normal" native-type="submit">
            <span >保存图片</span>
          </van-button> -->
        </div>
    </div>
    <br/>
</template>

<script>
import { reactive,ref,onMounted } from 'vue';
import sHeader from '@/components/Header';
import html2canvas from "html2canvas";
import { useRouter,useRoute } from 'vue-router';
import { getLocalUser,prefix} from '@/utils/function'
import {studentInfo} from '@/apis/student';
import {getExamTime} from '@/apis/exam';

import { Toast } from 'vant'
// import { dataTool } from 'echarts';
// import {ImagePreview} from 'vant';
export default {
   components: {
    sHeader
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const imageTofile = ref(null);
    const state = reactive({
      avatar:"",
      pictureType:"",
      htmlUrl: "",
      isShow: true,
      student:{},
      publicExam:{},
      user:getLocalUser('user'),
      identity:getLocalUser('user').identity
    });
     onMounted(async()=>{
        state.student = await studentInfo({id:route.query.student_id});
        state.publicExam = await getExamTime();
        // getImage(prefix(state.student.avatar));
        // await getImage(require('../../static-files/top.jpg'));
        setTimeout(function(){//网页生成图片用户保存
          onClickRight();
        },1000)
    });
    const onConfirm = (value) => {
      state.value = value;
      state.showPicker = false;
    };
    const onClickRight = () => {
     // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(imageTofile.value, {
        backgroundColor: null,
        useCORS:true
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        state.htmlUrl = url; // 把生成的base64位图片上传到服务器,生成在线图片地址
        state.isShow = false;
        // console.dir(new Date().toLocaleString());
        // saveFile(state.htmlUrl, '准考证.jpg')
        // if (state.htmlUrl) {
        //   // 渲染完成之后让图片显示，用v-show可以避免一些bug
        //   state.isShow = true;
        // }
        // this.sendUrl();
      });
    };

    const saveFile = (data,filename) => {
      const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
      save_link.href = data;
      save_link.download = filename;
      const event = document.createEvent('MouseEvents');
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      save_link.dispatchEvent(event);
      Toast('保存成功');
    }
    /**
     * url:传入的网络地址图片
     */
    const getImage=(url)=>{
      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext('2d')
      let image = new Image();
      image.crossOrigin="*";
      // alert(url+'?x='+Math.random());
      image.src = url+'?x='+Math.random();
      // alert(image.src);
      image.setAttribute("crossOrigin",'Anonymous')
      // alert('开始图片处理');
      image.onerror=()=>{
        // alert('进入onerror方法!');
        alert(image.width);
      }
      image.onload =()=> {
        // alert('进入onload方法!');
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);
        state.avatar = canvas.toDataURL("image/png");
      };
    }

    // const getBase64Image=(img)=>{
    //   let canvas = document.createElement("canvas");
    //   canvas.width = img.width;
    //   canvas.height = img.height;
    //   let ctx = canvas.getContext("2d");
    //   ctx.drawImage(img, 0, 0, img.width, img.height);
    //   let dataURL = canvas.toDataURL("image/png");
    //   return dataURL;
    // }

    const dashboardUrl = () => {
      router.push({ path: '/dashboard'})
    }
    const back=()=>{
        router.go(-1);
    }

    return {
      // getBase64Image,
      getImage,
      state,
      imageTofile,
      saveFile,
      onClickRight,
      back,
      onConfirm,
      dashboardUrl,
      prefix,
    };
  },
};
</script>

<style  scoped>
*{margin:0; padding:0; font-family:Microsoft Yahei;}
div{margin:0 auto;}
/* .center{text-align:center;} */

.b{width:100%; overflow:hidden;}
.b .box{float:left; width:100%; overflow:hidden;}
.b .box .top{overflow:hidden;}
.b .box .top img{float:left; width:100%;}
.b .box .con{padding:5px;}
.b .box .con .use{width:100%; border-collapse:collapse; }
.b .box .con .use tr{}
.b .box .con .use tr td{ font-size:15px; padding:4px 4px;}
.b .box .con .use tr td img{float:left; width:120px; height:164px;}
.b .box .con .use tr:nth-child(1) td{height:29px; font-size:16px; text-align:center;}

.b .box .con .inf{border:1px solid #222; border-top:none; padding:4px;}
.b .box .con .inf .u{ border-right:none; border-bottom:none; margin-bottom:4px;}
.b .box .con .inf .u .cou{width:100%; border-collapse:collapse;}
.b .box .con .inf .u .cou tr{}
.b .box .con .inf .u .cou tr:nth-child(1) td{font-weight:bold;}
.b .box .con .inf .u .cou tr td{font-size:15px; text-align:center; padding:4px 4px;}
.b .box .con .inf {font-size:15px;}
.b .box .bot{overflow:hidden;}
.b .box .bot img{float:left; width:100%;} 
.show-img{width:100%;	height:auto;};
</style>