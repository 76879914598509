<template>
<s-header :name='"-试卷查询"'></s-header>
<!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
<van-form @submit="onSubmit" :submit-on-enter="false">

  <van-tabs  v-model:active="active" @click="onClickTab">
      <van-tab title="公共检测">
        <van-field
            v-model="state.examValue"
            readonly
            name="exam_name"
            clickable
            label="选择检测"
            placeholder="点击选择检测 "
            @click="state.showPicker = true"
            :rules="[{ required: true, message: '请选择检测' }]"
          />
          <van-popup v-model:show="state.showPicker" position="bottom">
            <van-picker
              :columns="state.examPicker"
              @confirm="onConfirm"
              @cancel="state.showPicker = false"
            />
          </van-popup>
      </van-tab>
      <van-tab title="本校检测">
        <van-field
            v-model="state.examValue"
            readonly
            name="exam_name"
            clickable
            label="选择检测"
            placeholder="点击选择检测 "
            @click="state.showPicker = true"
            :rules="[{ required: true, message: '请选择检测' }]"
          />
          <van-popup v-model:show="state.showPicker" position="bottom">
            <van-picker
              :columns="state.selfColumns"
              @confirm="onConfirm"
              @cancel="state.showPicker = false"
            />
          </van-popup>
      </van-tab>
    </van-tabs>
    
    <van-field
      v-model="state.sortValue"
      readonly
      name="subject"
      clickable
      label="选择科目"
      placeholder="点击选择科目 "
      @click="state.showPicker2 = true"
      :rules="[{ required: true, message: '请选择科目' }]"
    />
    <van-popup v-model:show="state.showPicker2" position="bottom">
      <van-picker
        :columns="state.sortPicker"
        @confirm="onConfirmSort"
        @cancel="state.showPicker2 = false"
      />
    </van-popup>

     <van-field
          v-model="state.info.exam_id"
          label="检测id"
          name="exam_id"
          v-show="false"
      />

       <van-field
          v-model="state.info.sort"
          label="班级id"
          name="sort"
          v-show="false"
      />

    <div style="width:100%;height:100%;">
       <div style="margin: 10px;">
            <div>
            <router-link :to="{path:'/dashboard'}">
                <van-button style="width:48%;" plain type="primary" size="normal">
                <span style="color:#555555;">返回主页</span>
                </van-button>
            </router-link>
            <van-button  style="width:48%;margin-left:4%"  type="primary" size="normal" native-type="submit">
            <span >查询</span>
            </van-button>
            </div>
        </div>

      <div class="van-grid">
          <div  v-for="(item, index) in state.list.data" :key="index" class="van-grid-item" style="margin-left:3%;margin-right:3%;margin-bottom:3%;float:left;width:44%;height:auto;">
              <div class="van-grid-item__content van-grid-item__content--center">
                  <div class="van-image">
                      <img :src="prefix(item.photo)" @click="checkImage(prefix(item.photo))" class="van-image__img" style="object-fit: contain;">
                      <span>{{item.student_name}}-分数-{{item.fen}}</span>
                  </div>
              </div>
          </div>
          
      </div>
      <van-pagination v-model="currentPage" :page-count="state.list.last_page" mode="simple" @change="pageC" />
   
 <div class="bottom-bg"></div>
</div>
</van-form>


</template>
<script>
import { reactive,onMounted,ref} from 'vue';
import sHeader from '@/components/Header'
import {examList} from '@/apis/exam';
import { gradeList } from '@/apis/grade.js'
import { Toast } from 'vant';
import { prefix,getLocalUser} from '@/utils/function'
import {list} from "@/apis/school_test";
import { ImagePreview } from 'vant';
export default {
   components: {
    sHeader
  },
  setup() {
    const active = ref(0);
    const state = reactive({
      user:getLocalUser('user'),
      identity:getLocalUser('user').identity,
      sortPicker:[
      {id:'the_sketch',text:'素描'},
      {id:'sketch',text:'速写'},
      {id:'color',text:'色彩'},
      ],
      examPicker:[],
      examValue: '',
      clbumValue: '',
      sortValue: '',
      list:{},
      info:{},//提交的参数
      selfColumns:[]
    });
    const columns = ['杭州', '宁波', '温州', '嘉兴', '湖州'];
    const sort = ['综述', '素描', '速写', '色彩'];

    onMounted(async()=>{
        //微信扫描调用
        state.exam_list = await examList({search:1});//获取所有检测数据
        // state.examValue = state.exam_list[0]['text'];
        state.info.exam_id = state.exam_list[0]['id'];
        for(let i = 0; i < state.exam_list.length; i++){
              console.log( state.exam_list[i]);
              state.examPicker.push(state.exam_list[i]);
        }
        if(state.user.identity=='school')
        {
            state.selfColumns = await list({school_id:state.user.id,search:1});
        }else{
            state.selfColumns = await list({school_id:state.user.school_id,search:1});
        }
    });

    const onClickTab = () => {//tabs切换事件
      if(active.value)
      {
        state.examValue = state.selfColumns[0]["text"];
        state.info.exam_id = state.selfColumns[0]["id"];
      }else{
        state.examValue = state.examPicker[0]["text"];
        state.info.exam_id = state.examPicker[0]["id"];
      }
    };//tabs 标签点击事件
     const onConfirm = (value) => {
      state.examValue = value.text;
      state.info.exam_id = value.id;
      state.showPicker = false;
    };


    const onConfirmSort = (value) => {
      state.sortValue = value.text;
      state.info.sort = value.id;
      state.showPicker2 = false;
    };

    const onSubmit = async(values) => {
      values.active = active.value;
      values.exam_id=active.value?'':state.info.exam_id;//acive=0 录入公共检测字段
      values.self_exam_id= active.value?state.info.exam_id:'';//acive=0 录入本校检测字段字段
      // if(state.identity=='clbum'){//老师登录,查询结果处理
      //     values.clbum_id = state.user.id;
      // }
      values.type='page';//分页查询....
      Toast.loading({
        message: '请稍等...',
        forbidClick: true,
      });
      state.list = await gradeList(values);
      console.log('values',values);
      Toast.clear();
    };

    const pageC = async(value)=>{//分页查询
      Toast.loading({
            message: '请稍等...',
            forbidClick: true,
        });
      if(active.value){//本校检测
        state.list = await gradeList({page:value,type:'page',subject:state.sortValue,self_exam_id:state.info.exam_id});
      }else{//公共检测
        state.list = await gradeList({page:value,type:'page',subject:state.sortValue,exam_id:state.info.exam_id});
      }
      // if(state.identity=='clbum'){//老师登录,查询结果处理
      // }else{
      //   state.list = await gradeList({page:value,type:'page',subject:state.sortValue,exam_id:state.info.exam_id});
      // }
      Toast.clear();
    };
     const checkImage = (value) => {
      ImagePreview([
        value
      ]);
    };
    

    return {
      state,
      columns,
      sort,
      onConfirm,
      onConfirmSort,
      onSubmit,
      prefix,
      pageC,
      active,
      onClickTab,
      checkImage,
    };
  },
};
</script>
<style  scoped>
.font-color{
    color:#555555;
}
.van-row{
    border-bottom:1px solid #EBEBEB;
}
.van-col{
    height:4em;line-height:4em;
}
.bottom-bg {
  height: 0.9rem;
  width: 100%;
  /* background-color: #F6F6F6; */
  border: none;
}
.demo-preview{
    background: #ffff;
    margin:10px;
    height:9em;
}
p{
    color:#747474
}
.clbum-info{
    float:left;margin-left:5%;margin-top:7px;font-size:13px;width:80%;
}
h1{
    float:left;margin-top:25px;
}
.clbum-share{
float:right;margin-right:3%;
}

</style>