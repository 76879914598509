export default{
    condit: 
    [
    // {
    //   id:2021,
    //   text: '2021年',
    //   children: [
    //     {
    //       id:1,
    //       text: '第一次考试',
    //       children: [{ id:'the_sketch',text: '素描' }, {id:'sketch', text: '速写' },{id:'color', text: '色彩' }],
    //     },
    //     {
    //       id:2,
    //       text: '第二次考试',
    //       children: [{ id:'the_sketch',text: '素描' }, {id:'sketch', text: '速写' },{id:'color', text: '色彩' }],
    //     },
    //     {
    //         id:3,
    //       text: '第三次考试',
    //       children: [{ id:'the_sketch',text: '素描' }, {id:'sketch', text: '速写' },{id:'color', text: '色彩' }],
    //     },
    //   ],
    // },
    {
      id:2022,
      text: '2022年',
      children: [
        {
            id:1,
            text: '第一次考试',
            children: [{ id:'the_sketch',text: '素描' }, {id:'sketch', text: '速写' },{id:'color', text: '色彩' }],
          },
          {
            id:2,
            text: '第二次考试',
            children: [{ id:'the_sketch',text: '素描' }, {id:'sketch', text: '速写' },{id:'color', text: '色彩' }],
          },
          {
              id:3,
            text: '第三次考试',
            children: [{ id:'the_sketch',text: '素描' }, {id:'sketch', text: '速写' },{id:'color', text: '色彩' }],
          },
      ],
    },
  ],
  stallO: [
    {
      id: "0",
      text: "A+(95及以上)",
    },
    {
      id: "1",
      text: "A-(90-94)",
    },
    {
      id: "2",
      text: "B+(85-89)",
    },
    {
      id: "3",
      text: "B-(80-84)",
    },
    {
      id: "4",
      text: "C+(75-79)",
    },
    {
      id: "5",
      text: "C-(70-74)",
    },
    {
      id: "6",
      text: "D+(65-69)",
    },
    {
      id: "7",
      text: "D-(60-64)",
    },
    {
      id: "8",
      text: "E+(36-59)",
    },
    {
      id: "9",
      text: "E-(0-35)",
    },
  ],
}