<template>
<s-header :name='"订单支付"'></s-header>
<div style="background:#F6F6F6;width:100%;height:100%;">
<van-notice-bar color="#EE0A24" background="#ecf9ff" left-icon="info-o">
    报名成功,请牢记以下信息
</van-notice-bar>
<van-cell-group>
<div class="van-cell">
    <div class="van-cell__title">
        <span>所在考点：</span>
    </div>
    <div class="van-cell__value">
        {{state.student.school_name}}
    </div>
</div>

<div class="van-cell">
    <div class="van-cell__title">
        <span>所在班级：</span>
    </div>
    <div class="van-cell__value">
        {{state.student.clbum_name}}
    </div>
</div>

<div class="van-cell">
    <div class="van-cell__title">
        <span>考生姓名:</span>
    </div>
    <div class="van-cell__value">
        {{state.student.name}}
    </div>
</div>

<div class="van-cell">
    <div class="van-cell__title">
        <span>报名序号:</span>
    </div>
    <div class="van-cell__value">
        {{state.student.num}}
    </div>
</div>
</van-cell-group>

<van-notice-bar color="#EE0A24" background="#ecf9ff" left-icon="info-o">
    已选择检测
</van-notice-bar>
<van-cell-group>
        <!-- <van-cell  title="合计">
            <van-icon style="color:#EE0A24" name="success" />
        </van-cell> -->
        <div  v-for="(item, index) in state.hasInfo.examList" :key="index" class="van-cell">
            <div class="van-cell__title">
                <span>{{item.id}}.{{item.name}}</span>
            </div>
            <div class="van-cell__value">
                ￥{{item.price}}
            </div>
        </div>

        <div class="van-cell">
            <div class="van-cell__title">
                <span>合计:</span>
            </div>
            <div class="van-cell__value">
                ￥{{state.hasInfo.examPrice}}
            </div>
        </div>
        <br/>
</van-cell-group>

    <div v-if="state.open_id==''" style="margin: 10px;">
        <van-button @click="onPay" style="width:48%;" plain type="primary" size="normal">
            <span style="color:#555555;">微信支付</span>
        </van-button>
        <van-button @click="friendPay"  style="width:48%;margin-left:4%"  type="primary" size="normal" native-type="submit">
            <span >亲友/同学/老师代付</span>
        </van-button>
    </div>
    
    <div v-if="state.open_id!=''" style="margin: 10px;">
    <van-button block type="primary" @click="onPay">
        <span>微信支付</span>
    </van-button>
    </div>
</div>

<div class="bottom-bg"></div>
</template>
<script>
import { onMounted, reactive } from 'vue';
import sHeader from '@/components/Header'
import { Dialog,Toast } from 'vant'
import { useRouter,useRoute } from 'vue-router';
import {studentInfo} from '@/apis/student';
import {payOrder} from '@/apis/order';
import wechatInterface from "@/utils/wechatInterface";
import { decrypt} from '@/utils/openssl'
import {getJssdk} from '@/apis/student';
export default {
   components: {
    sHeader
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    // const orderNo = route.query.order_no;
    const studentId = route.query.student_id;
    const state = reactive({
      student:{},
      open_id:'',//亲朋好友支付,重新赋值open_id
      jssdk:{},
      hasInfo:{}
    })
    onMounted(async()=>{
        state.jssdk = await getJssdk({url:window.location.href});
        state.student = await studentInfo({id:studentId});
        state.hasInfo = state.student.hasInfo;
        console.log(state.student);
        // if(Object.keys(route.query).indexOf('dai')!=-1){//indexOf可以判断数组是否包含某个值，返回该值所对应的下标，对于不存在的值，返回 -1
        if(urlParam('dai')=='qy' && typeof route.query.q=='undefined'){//亲友支付,并且不是回调回来的页面.调用授权.....
            window.location.href = 'https://www.yszytb.com/api/v1/system/wechatOauth?url='+encodeURIComponent(window.location.href);//传递当前授权页面,进行回调处理....
        }else{
            if(Object.keys(route.query).indexOf('q')!=-1){//如果q参数存在,解析成open_id  存储(亲友支付时会走这段逻辑)
                let open_id = decrypt(route.query.q);
                state.open_id = open_id
                localStorage.setItem('open_id', open_id);
            }
        }
        // }
        
        onShare();//调用分享
    })
    //解决微信分享bug
    const urlParam = (name)=>
    {
        console.log(route.fullPath);
        let url = route.fullPath.replace(/&amp;/g, '&');
        console.log(url)
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        let r = url.substr(1).match(reg);
        return r ? r[2] : null;
    }

    const onShare=async()=>{
        wechatInterface(
            state.jssdk,
            () => {// 分享成功回调
                console.log('分享成功回调');
            },
            () => {// 分享失败回调
                Dialog.alert({
                    title: '提示',
                    message: '亲友分享接口调用失败,如需代付请刷新页面重试;刷新后无此提示表示调用成功;微信支付忽略此提示!',
                    theme: 'round-button',
                }).then(() => {
                // on close
                });
            },
            {
                type:'share',
                title:'2021美术基础教学质量检测检测费支付',
                desc:state.student.name+'报名参加2021年美术基础教学质量检测,请你代付检测费.',
                link:process.env.VUE_APP_WEB_HTTP+'/create-order?student_id='+state.student.id+'&order_no='+state.student.order_no+'&dai=qy',
                imgUrl:process.env.VUE_APP_WEB_HTTP+'/630.png'
            },
        );
    }

    const onPay=async()=>{
        let order_no = urlParam('order_no');
        console.log(order_no);
        let result = await payOrder({order_no:order_no,open_id:localStorage.getItem('open_id')});//使用当前手机,登录的open_id支付....
        if(typeof result.appId=="undefined"){
            window.location.href=result.payurl;//跳转到易宝收银台支付..
        }else{//微信支付
            wechatInterface(
                result,
                () => {// 支付成功回调
                    console.log('支付成功回调');
                    Toast('支付成功');
                    router.push({ path: '/dashboard'})
                },
                () => {// 支付失败回调
                    console.log('支付失败回调');
                    Toast('支付失败,请重试');
                },
            );
        }
    }
    const confirm=()=>{
        router.push({ path: '/'})
    }
    const friendPay=()=>
    {
        Dialog.alert({
            title: '亲友代付须知',
            message: '请点击右上角,将连接发送给亲友支付;如需主考/老师代付,请将报名序号告知主考/老师进行支付;分享完成后请关闭此页面;重新进入系统!',
            theme: 'round-button',
        }).then(() => {
            // onShare();
        });
    }
    const back=()=>{
        router.go(-1);
    }
    //使用微信支付   
    return {
      urlParam,
      state,
      confirm,
      friendPay,
      onPay,
      back,
    };
  },
};
</script>
<style  scoped>
.font-color{
    color:#555555;
}
.van-row{
    border-bottom:1px solid #EBEBEB;
}
.van-col{
    height:4em;line-height:4em;
}
.bottom-bg {
  height: 0.9rem;
  width: 100%;
  background-color: #F6F6F6;
  border: none;
}
.demo-preview{
    background: #ffff;
    margin:10px;
    height:7em;;
}
.van-cell__value{
    color:#EE0A24;
}
p{
    color:#747474
}
.clbum-info{
    float:left;margin-left:5%;margin-top:7px;font-size:13px;width:80%;
}
h1{
    float:left;margin-top:25px;
}
.clbum-share{
    float:right;margin-right:3%;
}
</style>