<template>
<s-header :name='"-完善信息"' ></s-header>
    <van-cell-group>
        <van-cell title="考点代码：" :value="state.clbum.school_code" />
        <van-cell title="考点名称：" :value="state.clbum.school_name" />
        <van-cell title="所在班级：" :value="state.clbum.name" />
    </van-cell-group>
    <!-- <div style="margin-left:15px;font-size:13px;"><span style="color:red">说明：</span>请仔细核对以下信息是否正确，如不正确可直接修改</div><br/> -->
    <van-notice-bar wrapable color="#1989fa" background="#ecf9ff" left-icon="info-o">
      拍摄本人头像要求:请使用手机摄像头拍摄考生本人正面头像,背景要求白墙或者白布,照片将打印在准考证上
    </van-notice-bar>

    <van-form @submit="onSubmit" :submit-on-enter="false">
    <van-field
        v-model="state.student.name"
        name="name"
        maxlength=6
        label="考生姓名"
        required
        placeholder="请填写考生姓名"
        :rules="[{ required: true, message: '请填写考生姓名' }]"
    />

  <van-field  required name="sex" label="考生性别">
    <template #input>
      <van-radio-group v-model="state.student.sex" direction="horizontal">
        <van-radio name="0">男</van-radio>
        <van-radio name="1">女</van-radio>
      </van-radio-group>
    </template>
  </van-field>
 <van-field
    v-model="state.student.password"
    label="考生密码"
    required
    maxlength=6
    name="password"
    placeholder="任意设置6位数字密码（请牢记）"
    :rules="[{ required: true, message: '请填写考生密码' },{pattern,message:'请输入6位数密码'}]"
  />
  <br/>
  <div style="margin-left:5%;">
    <van-uploader v-model="fileList"  :after-read="afterRead" :max-count="1" previewSize="120" />
  </div>

<!-- 隐藏的属性值........................ -->
  
   <van-field
    v-model="state.student.avatar"
    label="头像地址"
    name="avatar"
    v-show="false"
    placeholder="头像地址"
  />


  <van-field
      v-model="state.clbum.school_id"
      label="学校id"
      name="school_id"
      v-show="false"
  />

  <van-field
      v-model="state.clbum.school_name"
      label="学校名称"
      name="school_name"
      v-show="false"
  />

  <van-field
      v-model="state.clbum.id"
      label="班级id"
      name="clbum_id"
      v-show="false"
  />

  <van-field
      v-model="state.clbum.name"
      label="班级名称"
      name="clbum_name"
      v-show="false"
  />
  <br/>
   <div style="margin: 0px 10px;">
        <div v-if="state.z_config=='1'">
          <van-button style="width:48%;" plain type="primary" size="normal" @click="admissionTicketUrl">
            <span style="color:#555555;">我的准考证</span>
          </van-button>
          <van-button  style="width:48%;margin-left:4%"  type="primary" size="normal" native-type="submit">
            <span >确认无误</span>
          </van-button>
        </div>

        <div v-if="state.z_config=='0'">
          <van-button block type="primary" size="normal" native-type="submit">
            <span >确认无误</span>
          </van-button>
        </div>
    </div>
  </van-form>
</template>
<script>
import { onMounted, reactive } from 'vue';
import { Toast } from 'vant';
import { useRouter,useRoute } from 'vue-router';
import sHeader from '@/components/Header';
import {addStudent,studentInfo,updateStudent} from '@/apis/student';
import {clbumInfo} from '@/apis/clbum';
import { createOrder } from '@/apis/order';
import { ref } from 'vue';
import { getLocal,setLocal,getConfig,prefix} from '@/utils/function'
import { login,upload } from '@/apis/user';
import axios from 'axios'
export default {
   components: {
    sHeader
  },
  //业务分为 代报 =dai  学生自主报名 =create
  setup() {
    const pattern = /^\S{6,}$/;
    const router = useRouter();
    const route = useRoute();
    const fileList = ref([]);
    const state = reactive({
      showArea: false,
      clbum:{},
      submit:'add',//确认后,操作类型
      student:{
        name:'',
        sex:"0",
        password:'',
        avatar:''
      },
      z_config:'',//准考证配置
    });

    onMounted(async()=>{
      state.z_config = getConfig('dmission_ticket').value;
      if(state.z_config==undefined){
        state.z_config='0';
      }
      console.log(state.z_config);
      if(route.query.type=="dai" || route.query.type=="create")
      {//代付或者未注册考生创建账号
        state.clbum   =  await clbumInfo({id:route.query.clbum_id});
      }else{
        state.student =  await studentInfo({id:route.query.student_id});
        state.clbum   =  await clbumInfo({id:state.student.clbum_id});
        if(state.student.avatar){
          fileList.value.push({ url: prefix(state.student.avatar) });
        }
        state.submit = 'edit';//编辑用户
      }
      console.log(state.clbum);
    })

     const afterRead = async(file) => { // 此时可以自行将文件上传至服务器
         Toast.loading({
          message: '上传中...',
          forbidClick: true,
        });
        let formdata = new FormData();
        formdata.append("file",file.file);
        let updateData = await upload(formdata);
        Toast('上传成功');
        state.student.avatar = updateData;
    };

    const onSubmit = async (values) => {
       Toast.loading({
        message: '请稍等...',
        forbidClick: true,
      });
      if(state.submit=='add'){//创建用户
          if(route.query.type!='dai')
          {//代报不用传递考生头像,本人报名必须上传本人头像
            if(state.student.avatar==''){
              Toast('请上传本人头像');
              return;
            }
          }
          // values.open_id = localStorage.getItem('open_id');
          let user =  await addStudent(values);
          console.log('userInfo',user);
          let dataParams = {
            student_id:user.id,
            // open_id:user.open_id||getLocalUser('user').open_id,
            open_id:getLocal('open_id'),
            clbum_id:user.clbum_id,
            school_id:user.school_id,
            dai:1,
            dai_user:getLocal('user'),
          }
          let order = await createOrder(dataParams);


          if(route.query.type=='create')
          {//登录用户--业务流-未注册用户登录-分享班级连接(考生报名)
            let params  = {
              reg:1,
              code:user.num,
              password:user.password,
              open_id:getLocal('open_id'),
              identity:'student'
            }
            onLogin(params);//未注册用户登录-分享班级连接(考生报名)登录
          }
          if(state.clbum.exam_price>0){//金额大于0进入支付页面.
             Toast('操作成功')
            setTimeout(() => {
              router.push({ path: '/create-order',query:{student_id:user.id,order_no:order.order_no,clbum_id:user.clbum_id,school_id:user.school_id}})
            }, 1000)
          }else{
            Toast('报名成功')
            setTimeout(() => {
              router.push({ path: '/dashboard'})
            }, 1000)
          }
      }else{//更新用户资料
          values.id=state.student.id;
          console.log(values);
          await updateStudent(values);
          Toast('更新成功')
          setTimeout(() => {
            router.back(-1);
            // router.push({ path: '/dashboard'})
          }, 1000)
        }
    };
     const onLogin =  async (values) => {//主考  班主任登录用同一个函数....
     console.log(values);
      let data = await login(values);
      setLocal('user', JSON.stringify(data));
      setLocal('token', data.authorization);
      axios.defaults.headers['Authorization'] = localStorage.getItem('token')
      // 需要刷新页面，否则 axios.js 文件里的 token 不会被重置
    };

     const admissionTicketUrl = () => {
       if(state.student.exam_num>0 && state.student.seat_num>0)
       {//生成座位号,考场号后可以查看...
          router.push({ path: '/admission-ticket',query:{student_id:state.student.id}});
       }else{
         Toast('所有报名统计完成后可以查看!');
       }
    }
    const showPopup = () => {
      console.log(state.show);
      state.show = true;
    };
    return {
      state,
      onSubmit,
      showPopup,
      afterRead,
      admissionTicketUrl,
      // base64UrlToFile,
      pattern,
      onLogin,
      fileList,
      prefix
    };
  },
};
</script>
<style  scoped>

</style>