<template>
<s-header ></s-header>

<div v-if="state.identity=='school'">
  <van-row class="van-row">
    <van-col class="van-col" span="23" offset="1">考点: {{state.user.name}}</van-col>
  </van-row>
  <van-row class="van-row">
    <van-col class="van-col" span="11" offset="1">已报名考生: {{state.count.count}}</van-col>
    <van-col class="van-col" span="11">检测费已缴: {{state.count.pay_count}}</van-col>
  </van-row>
  <van-row class="van-row">
      <van-col class="van-col" span="11" offset="1">未缴费考生: {{state.count.not_pay_count}}</van-col>
      <van-col class="van-col" span="11">考点代码: {{state.user.code}}</van-col>
  </van-row>
</div>

<div v-if="state.identity=='clbum'">
  <van-row class="van-row">
    <van-col class="van-col" span="11" offset="1">报名点: {{state.user.school_name}}</van-col>
    <van-col class="van-col" span="11" offset="1">班级: {{state.user.name}}</van-col>
  </van-row>
  <van-row class="van-row">
    <van-col class="van-col" span="11" offset="1">本班已报名人数: {{state.count.count}}</van-col>
    <van-col class="van-col" span="11">检测费已缴: {{state.count.pay_count}}</van-col>
  </van-row>
  <van-row class="van-row">
      <van-col class="van-col" span="23" offset="1">未缴费考生: {{state.count.not_pay_count}}</van-col>
  </van-row>
</div>
<!-- <van-image
  round
  width="2rem"
  height="2rem"
  src="https://img.yzcdn.cn/vant/cat.jpeg"
/> -->
<div v-if="state.identity=='student'" style="height:auto;margin:10px;">
  <!-- <img style="width:50px;height:50px;border-radius:50px;" src="https://img.yzcdn.cn/vant/cat.jpeg"/> -->
  <div>
      <van-image
      round
      width="1.5rem"
      height="1.5rem"
      :src="prefix(state.student.avatar)"
    />
    <div style="float:right;margin-top:2%;width:80%;">
      <span>
        姓名:{{state.student.name}} <span v-if="state.student.sex=='0'">(男)</span><span v-if="state.student.sex=='1'">(女)</span> 报名号:{{state.student.num}}<br/>
        报名点:{{state.student.school_name}}<br/>
        <span v-if="state.hasInfo.repairExam && state.hasInfo.repairExam.length>0">检测费:￥{{state.hasInfo.examPrice}} (未缴纳:{{state.hasInfo.repairExamPrice}})</span> 
        <span v-else>检测费:￥{{state.hasInfo.examPrice}} (已缴纳)</span>
        <br/>
      </span>
    </div>
  </div>
</div>

<div style="background:#F6F6F6;height:auto;">
    <br/>
    <!-- 主考管理页面 -->
    <van-grid v-if="state.identity=='school'" :gutter="10" icon-size="30" clickable=true>
        <van-grid-item icon="location-o" text="考点信息" @click="shcoolUrl" />
        <van-grid-item icon="star-o" text="班级管理" @click="clbumUrl" />
        <van-grid-item icon="bullhorn-o" text="报名管理" @click="enrollUrl" />
        <van-grid-item icon="balance-pay" text="缴费管理" @click="paymentUrl" />
        <van-grid-item icon="photo-o" text="照片审核" @click="photoUrl" />
        <van-grid-item icon="todo-list-o" text="本校测评" @click="evaluationUrl" />
        <van-grid-item icon="description" text="成绩管理" @click="gradeUrl" />
        <van-grid-item icon="records" text="成绩分析" @click="gradeAnalyseUrl" />
        <van-grid-item icon="eye-o" text="试卷查询" @click="papersSearchUrl" />
        <van-grid-item icon="good-job-o" text="优秀试卷" @click="goodsPapersUrl" />
    </van-grid>
    <!-- 班主任管理页面 -->
    <van-grid v-if="state.identity=='clbum'" :gutter="10" icon-size="30" clickable=true>
        <van-grid-item icon="location-o" text="班级信息" @click="shcoolUrl" />
        <van-grid-item icon="bullhorn-o" text="报名管理" @click="enrollUrl" />
        <van-grid-item icon="description" text="成绩管理" @click="gradeUrl" />
        <van-grid-item icon="photo-o" text="照片审核" @click="photoUrl" />
        <van-grid-item icon="records" text="成绩分析" @click="gradeAnalyseUrl" />
        <van-grid-item icon="eye-o" text="试卷查询" @click="papersSearchUrl" />
        <van-grid-item icon="good-job-o" text="优秀试卷" @click="goodsPapersUrl" />
    </van-grid>
     <!-- 考生管理页面 -->
    <van-grid v-if="state.identity=='student'" :gutter="10" icon-size="30" clickable=true>
       <van-grid-item icon="location-o" text="个人信息" @click="daiEnrollUrl(state.student.id)" />
        <van-grid-item icon="star-o" text="检测信息" @click="commonStudentUrl(state.student.id,1)" />
        <van-grid-item icon="bullhorn-o" text="补报检测" @click="commonStudentUrl(state.student.id,2)" />
        <van-grid-item icon="balance-pay" text="缴费管理" @click="commonStudentUrl(state.student.id,3)" />
        <van-grid-item icon="photo-o" text="成绩查询" @click="papersSearchUrl" />
        <van-grid-item icon="todo-list-o" text="成绩曲线图" @click="gradeGraphUrl" />
        <van-grid-item icon="description" text="试卷对比" @click="commonStudentUrl(state.student.id,4)" />
        <van-grid-item icon="records" text="成绩分析" @click="gradeAnalyseUrl" />
        <van-grid-item icon="eye-o" text="阶段目标" @click="stageGoalUrl" />
        <van-grid-item icon="good-job-o" text="最终目标" @click="ultimateGoalUrl" />
    </van-grid><br/>

    <div v-if="state.identity=='school'" style="margin: 10px;">
        <van-button @click="isShow" block type="primary">
            <span>为学生代付检测费</span>
        </van-button>
    </div>

    <div v-if="state.identity=='clbum'" style="margin: 10px;">
        <van-button @click="isShow" block type="primary">
            <span>为学生代付检测费</span>
        </van-button>
    </div>

    <div v-if="state.identity=='student'" style="margin: 10px;">
        <van-button @click="isShow" block type="primary">
            <span>为同学代付检测费</span>
        </van-button>
    </div>

    <div style="margin: 10px;">
        <van-button @click="onLogOut" plain block type="primary" native-type="submit">
            <span style="color:black">解除本人微信登录</span>
        </van-button>
    </div>


     <van-dialog v-model:show="state.addShow" title="代付考费" @confirm="onOrderInfo" show-cancel-button>
            <van-field
                v-model="state.num"
                name="num"
                placeholder="请填写代付检测费的考生号"
                :rules="[{ required: true, message: '请填写班级名称' }]"
            />
        </van-dialog>

    <div class="bottom-bg"></div>
</div>
</template>
<script>
import { reactive ,onMounted} from 'vue';
import { useRouter } from 'vue-router';
import { orderInfo } from '@/apis/order.js'
import { schoolStat,schoolInfo} from '@/apis/user.js'
import sHeader from '@/components/Header'
import { logOut} from '@/apis/user';
import { Toast,Dialog } from 'vant';
import { getLocalUser,prefix} from '@/utils/function'
import {studentInfo} from '@/apis/student';
import { configList } from "@/apis/config.js";
 import axios from 'axios'
export default {
   components: {
    sHeader
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      addShow:false,
      num:'',
      user:getLocalUser('user'),
      identity:getLocalUser('user').identity,
      student:{},
      school:{},
      count:{},
      hasInfo:{}
    });
    console.log('stoage',state.user);
    console.log('stoage',state.identity);
    onMounted(async()=>{
      axios.defaults.headers['Authorization'] = localStorage.getItem('token');
      let config = await configList();
      localStorage.setItem('config', JSON.stringify(config));
      if(state.identity!='student')
      {
        state.count = await schoolStat();
        console.log(state.count);
      }else{
        state.student = await studentInfo({id:state.user.id});
        state.hasInfo = state.student.hasInfo;
        console.log('state.student',state.student);
      }
      state.school = await schoolInfo();
      console.log('school',state.school);
    })
    const onSubmit = (values) => {
      console.log('submit', values);
    };

    const shcoolUrl = () => {
      if(state.identity=='school'){
        router.push({ path: '/perfect-info'})
      }else{
        router.go(0);
        // router.push({ path: '/dashboard'})
      }
    }
    const clbumUrl = () => {
      router.push({ path: '/clbum'})
    }

    const enrollUrl = () => {
      router.push({ path: '/enroll'})
    }

     const paymentUrl = () => {
      router.push({ path: '/payment'})
    }

    const photoUrl = () => {
      router.push({ path: '/photo'})
    }

    const evaluationUrl = () => {
      router.push({ path: '/evaluation'})
    }

    const gradeUrl = () => {
      gradeLookRule('/grade');
    }

    const gradeAnalyseUrl = () => {
      gradeLookRule('/grade-analyse');
    }

    const papersSearchUrl = () => {
      gradeLookRule('/papers-search');
    }

    const daiEnrollUrl = (student_id) => {
      router.push({ path: '/dai-enroll', query: { type: 'add', student_id: student_id }})
    }

    const commonStudentUrl = (student_id,skip) => {//检测信息.补报检测,缴费管理.....

      if(skip==4){//试卷对比权限处理
        gradeLookRule('/common-student',{student_id: student_id,skip:skip });
      }else{
        router.push({ path: '/common-student', query: {student_id: student_id,skip:skip }});
      }
    }

    const stageGoalUrl = () => {//阶段目标
      router.push({ path: '/stage-goal'})
    }

    const ultimateGoalUrl = () => {//最终目标
      router.push({ path: '/ultimate-goal'})
    }

    const gradeGraphUrl = () => {//考生成绩图表
      gradeLookRule('/grade-graph');
    }

    const goodsPapersUrl = () => {
         router.push({ path: '/good-paper'});//跳转到优秀试卷页面.
      // router.push({ path: '/marking-login'});//跳转到登录阅卷系统
      //  Dialog.alert({
      //       title: '提示',
      //       message: '提取码:1234',
      //       theme: 'round-button',
      //   }).then(() => {
      //     window.location.href='https://pan.baidu.com/wap/init?surl=xnfwO10o3CZW9GP8S6ReQw';
      //   // on close
      //   });
      // router.push({ path: 'http://www.baidu.com', query: { type: 'add', from: state.from }})
    }
    //成绩查看权限判断.....
    const gradeLookRule=(value,query={})=>{
       if(state.school.check_status=='0' && state.identity!='school'){//班级和考生查看成绩权限判断.....
          Dialog.alert({
              title: '提示',
              message: '数据积极整理中,请等待阅卷老师处理完成后查看',
              theme: 'round-button',
          }).then(() => {
          });
        }else{
          router.push({ path: value,query:query})
        }
    }

    const isShow = () => {
        state.addShow=true;
    }

    const onOrderInfo=(async()=>{
        const params = {student_num:state.num};
        let result = await orderInfo(params);
        console.log('result',result);
        router.push({ path: '/create-order',query:{student_id:result.student_id,order_no:result.order_no,clbum_id:result.clbum_id,school_id:result.school_id}})
    })
    
    const onLogOut = async() => {
      await logOut();
      Toast('退出成功');
      localStorage.setItem('token','');
      localStorage.setItem('user','');
      // localStorage.setItem('open_id','');
      router.push({ path: '/',query:{'out':'1'}})
    }

    return {
      state,
      onSubmit,
      shcoolUrl,
      clbumUrl,
      photoUrl,
      goodsPapersUrl,
      paymentUrl,
      enrollUrl,
      evaluationUrl,
      gradeUrl,
      gradeAnalyseUrl,
      papersSearchUrl,
      daiEnrollUrl,
      isShow,
      onOrderInfo,
      commonStudentUrl,
      onLogOut,
      stageGoalUrl,
      ultimateGoalUrl,
      gradeGraphUrl,
      gradeLookRule,
      prefix
    };
  },
};
</script>
<style  scoped>
.font-color{
    color:#555555;
}
.van-row{
    border-bottom:1px solid #EBEBEB;
}
.van-col{
    height:4em;line-height:4em;
}
.bottom-bg {
  height: 7em;
  width: 100%;
  background-color: #F6F6F6;
  border: none;
}
</style>